<template>
  <div class="week-calendar">
    <b-row v-if="returnDrivers.day" class="mb-4">
      <b-alert show variant="info" v-if="returnCurrentUsers"
        >Edycja ({{ returnCurrentUsers.length }})
        <div class="users-list">
          <div
            class="single-user"
            v-for="user in returnCurrentUsers"
            :key="user"
          >
            {{ user }}<span v-if="returnCurrentUsers.length > 1">,</span>
          </div>
        </div>
      </b-alert>
    </b-row>
    <b-row
      class="d-flex justify-content-between title"
      v-if="returnDrivers.day"
    >
      <div class="d-flex head">
        <h3>Zmiany dzienne</h3>
        <b-button class="ml-2 mt-1" variant="secondary" @click="showAssigned"
          >Przypisane pojazdy <b-icon icon="person-check-fill"
        /></b-button>
        <b-button class="ml-2 mt-1" variant="warning" @click="showBlock"
          >Przypisz pojazd <b-icon icon="plus-circle"
        /></b-button>
        <b-button class="ml-2 mt-1" variant="secondary" v-b-modal.add-rental
          >Nowy wynajem <b-icon icon="plus-circle"
        /></b-button>
        <b-button class="ml-2 mt-1" variant="warning" v-b-modal.add-grounded
          >Nowe uziemienie <b-icon icon="plus-circle"
        /></b-button>
      </div>
      <div class="action">
        <b-button
          v-if="changes.length"
          class="mr-3"
          variant="warning"
          v-b-modal.changes
        >
          Zmiany ({{ changes.length }})
        </b-button>
        <b-button class="mr-3" variant="danger" @click="reset">
          {{ startState ? "Aktualny stan" : "Stan początkowy" }}
        </b-button>
        <b-button variant="primary" @click="generateSchedule">{{
          update ? "Aktualizuj grafik" : "Generuj grafik"
        }}</b-button>
      </div>
    </b-row>
    <b-row d-flex class="box-row" v-if="returnDrivers.day">
      <div
        class="shift-box d-flex flex-column justify-content-between"
        v-for="(item, index) in createDays"
        :key="index"
      >
        <div
          class="box-title d-flex justify-content-between align-items-center"
        >
          <div class="left">
            {{ createDays[index] }}<br />{{ weekNames[index] }}
          </div>
          <div class="right" v-if="returnDrivers && returnCars">
            {{ returnCapacity.day[index].drivers }}/{{
              returnCapacity.day[index].availableCars
            }}
          </div>
        </div>
        <div
          class="box-item day"
          v-for="(car, carIndex) in returnDrivers.day[index].cars"
          :key="car.reg"
          :class="[
            car.grounded ? 'grounded' : '',
            car.notOnStation ? 'missing' : '',
          ]"
        >
          <draggable
            group="days"
            class="text-center dropbox"
            :list="car.drivers"
            @end="handleEndDay"
            :scroll-sensitivity="200"
            :force-fallback="true"
          >
            <div
              class="single"
              :class="[
                driver.type && driver.type === 'Wyłączność' ? 'rental' : '',
                driver.type && driver.type === 'Wynajem' ? 'rented' : '',
                hasTwoShiftsOnSameDay(driver, index, 'day'),
              ]"
              v-for="driver in car.drivers"
              :key="driver.id"
              @dblclick="removeShift(driver, index, carIndex, 'day')"
            >
              {{ driver.fullName.split(" ")[0] }}<br />{{
                driver.fullName.split(" ")[1].length
                  ? driver.fullName.split(" ")[1]
                  : driver.fullName.split(" ")[2]
              }}
              <div class="info" v-if="car.isRented">{{ driver.type }}</div>
            </div>
          </draggable>
          <div class="regplate">
            <div class="float-left" :id="car.reg + index" v-if="car.service">
              <b-icon icon="bullseye" variant="danger" />
              <b-tooltip
                placement="bottom"
                :target="car.reg + index"
                triggers="hover"
              >
                <strong>Zaplanowany serwis:</strong><br />
                <div
                  class="service-box"
                  v-for="item in car.service"
                  :key="item.desc"
                >
                  {{ item.desc }}<br />
                  {{ item.serviceTime[0] }}:{{ item.serviceTime[1] }}
                </div>
              </b-tooltip>
            </div>
            {{ car.reg }}
          </div>
        </div>
        <div class="reserve" :id="`popover-${index}`">
          Rezerwa ({{ returnDrivers.day[index].reserve.length }})
        </div>
        <b-popover
          :target="`popover-${index}`"
          triggers="click blur"
          placement="bottom"
          class="pop"
        >
          <template #title
            >Lista rezerwowa <br />{{ createDays[index] }}<br />{{
              weekNames[index]
            }}</template
          >
          <draggable
            class="drop-reserve dayr"
            group="days"
            :list="returnDrivers.day[index].reserve"
            @end="handleEndDay"
          >
            <div
              class="reserve-item"
              v-for="item in returnDrivers.day[index].reserve"
              :key="item.id"
            >
              {{ item.fullName }}
              <div class="assign" @click="altReserve(item)">przypisz</div>
            </div>
          </draggable>
        </b-popover>
      </div>

      <!-- noc -->
    </b-row>
    <b-row class="title" v-if="returnDrivers.night">
      <h3>Zmiany wieczorne</h3>
    </b-row>
    <b-row d-flex class="box-row" v-if="returnDrivers.night">
      <div class="shift-box" v-for="(item, index) in createDays" :key="index">
        <div
          class="box-title d-flex justify-content-between align-items-center"
        >
          <div class="left">
            {{ createDays[index] }}<br />{{ weekNames[index] }}
          </div>
          <div class="right" v-if="returnDrivers && returnCars">
            {{ returnCapacity.night[index].drivers }}/{{
              returnCapacity.night[index].availableCars
            }}
          </div>
        </div>
        <div
          class="box-item night"
          v-for="(car, carIndex) in returnDrivers.night[index].cars"
          :class="[
            car.grounded ? 'grounded' : '',
            car.notOnStation ? 'missing' : '',
          ]"
          :key="car.reg"
        >
          <draggable
            group="days"
            class="text-center dropbox"
            :list="car.drivers"
            @end="handleEndDay"
            :scroll-sensitivity="200"
            :force-fallback="true"
          >
            <div
              class="single"
              :class="[
                driver.type && driver.type === 'Wyłączność' ? 'rental' : '',
                driver.type && driver.type === 'Wynajem' ? 'rented' : '',
                hasTwoShiftsOnSameDay(driver, index, 'night'),
              ]"
              v-for="driver in car.drivers"
              :key="driver.id"
              @dblclick="removeShift(driver, index, carIndex, 'night')"
            >
              {{ driver.fullName.split(" ")[0] }}<br />{{
                driver.fullName.split(" ")[1].length
                  ? driver.fullName.split(" ")[1]
                  : driver.fullName.split(" ")[2]
              }}
              <div class="info" v-if="car.isRented">{{ driver.type }}</div>
            </div>
          </draggable>
          <div class="regplate">{{ car.reg }}</div>
        </div>
        <div class="reserve" :id="`popover-${index + 10}`">
          Rezerwa ({{ returnDrivers.night[index].reserve.length }})
        </div>
        <b-popover
          :target="`popover-${index + 10}`"
          triggers="click blur"
          placement="bottom"
          class="pop"
        >
          <template #title
            >Lista rezerwowa <br />{{ createDays[index] }}<br />{{
              weekNames[index]
            }}
          </template>
          <draggable
            class="drop-reserve nightr"
            group="days"
            :list="returnDrivers.night[index].reserve"
            @end="handleEndDay"
          >
            <div
              class="reserve-item"
              v-for="item in returnDrivers.night[index].reserve"
              :key="item.id"
            >
              {{ item.fullName }}
              <div class="assign" @click="altReserve(item)">przypisz</div>
            </div>
          </draggable>
        </b-popover>
      </div>
      <!--  -->
    </b-row>
    <b-modal
      id="car-block"
      title="Przypisz pojazd do kierowcy"
      ok-title="Dodaj zmiany"
      @ok="handleCarAssigment"
      cancel-title="Anuluj"
      size="xl"
      centered
    >
      <b-alert variant="warning" show
        >Aby zmiany zostały zapisane po kliknięciu
        <strong>,,Dodaj zmiany"</strong> musisz zaktualizować grafik.</b-alert
      >
      <b-form-group label="Imię i nazwisko kierowcy">
        <b-form-input list="driver-lists" v-model="selectedDriver">
        </b-form-input>
        <datalist id="driver-lists">
          <option v-for="driver in returnDriversNames">
            {{ driver.fullName }}
          </option>
        </datalist>
      </b-form-group>
      <div v-if="returnDriverInfo">
        <label>Wybierz samochód</label>
        <b-row>
          <b-col v-for="car in returnDriverInfo.possibleCars" cols="6"
            ><div
              class="car"
              :class="selectedDriverCar === car.reg ? 'active' : ''"
              @click="selectedDriverCar = car.reg"
            >
              {{ car.reg }}<br />Auto przypisane:
              <strong>{{ car.num }}x</strong>
            </div></b-col
          >
        </b-row>
        <b-form-group label="Ustawienia">
          <b-form-checkbox-group
            v-model="selectedOptions"
            :options="carAssignOptions"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group label="Wybierz dni tygodnia">
          <b-form-checkbox-group
            v-model="selectedDays"
            :options="dayNames"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </b-modal>
    <b-modal
      id="changes"
      title="Wprowadzone zmiany"
      ok-title="Zamknij"
      cancel-title="Anuluj"
      size="xl"
      centered
    >
      <b-row class="changes-row">
        <b-button
          variant="danger"
          @click="sendAllSMS"
          :disabled="sendedAll"
          block
          >Wyślij SMS do wszystkich <b-icon icon="exclamation-triangle"
        /></b-button>
        <b-col cols="6" v-for="item in changes" :key="item.id">
          <div class="changes-box text-center">
            <div class="mb-2">
              Kierowca: <strong>{{ item.fullName }}</strong>
            </div>
            <div class="d-flex my-3">
              <div class="oldshift w-50 ml-2">
                {{ item.oldShift }}<br />
                {{ item.oldDay }}<br />
                {{ item.oldWeekDay }}
              </div>
              <div class="newshift w-50 ml-2">
                {{ item.shift }}<br />
                {{ item.day }}<br />
                {{ item.weekDay }}<br />
                Nowy pojazd: <strong>{{ item.newReg }}</strong>
              </div>
            </div>
            <b-button
              class="mt-2"
              v-if="!sended.find((el) => el.id === item.id)"
              @click="sendSMS(item)"
              variant="primary"
              >Prześlij SMS <b-icon icon="card-text"
            /></b-button>
            <b-button disabled class="mt-2" v-else>SMS Wysłany</b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <alt-reserve
      v-if="returnDrivers"
      :selected="selectedRes"
      :days="createDays"
      :weekNames="weekNames"
      :capacity="returnCapacity"
      @setReserve="changeReserve"
    />
    <b-toast id="assigned" no-auto-hide v-if="getAssignedList">
      <template #toast-title> Przypisani kierowcy do pojazdów </template>
      <div class="text-center" v-for="item in getAssignedList">
        <div v-if="item.drivers.length" class="dlist">
          <div>
            <strong>{{ item.reg }}</strong>
          </div>
          <div v-for="drv in item.drivers">
            {{ drv.fullName }}
          </div>
        </div>
      </div>
    </b-toast>
    <new-rental @sent="fetchSettings" />
    <new-grounded @sent="fetchSettings" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import altReserve from "../components/modals/AltReserve.vue";
import NewRental from "../components/modals/NewRental.vue";
import NewGrounded from "../components/modals/NewGrounded.vue";
export default {
  components: { draggable, altReserve, NewRental, NewGrounded },
  data() {
    return {
      takenCars: [],
      isLoading: false,
      startState: false,
      sendedAll: false,
      live: null,
      onView: true,
      selectedRes: "Im empty",
      selectedDriver: null,
      selectedDriverCar: null,
      noFreeCarError: false,
      selectedOptions: [],
      selectedDays: [],
      carAssignOptions: [
        {
          text: "Przypisz na poranne zmiany",
          value: "days",
        },
        {
          text: "Przypisz na popołudniowe zmiany",
          value: "nights",
        },
        {
          text: "Usuń z innych pojazdów",
          value: "remove",
        },
      ],
      dayNames: [
        {
          text: "Poniedziałek",
          value: 0,
        },
        {
          text: "Wtorek",
          value: 1,
        },
        {
          text: "Środa",
          value: 2,
        },
        {
          text: "Czwartek",
          value: 3,
        },
        {
          text: "Piątek",
          value: 4,
        },
        {
          text: "Sobota",
          value: 5,
        },
        {
          text: "Niedziela",
          value: 6,
        },
      ],
      sended: [],
      isList: false,
      weekNames: [
        "Poniedziałek",
        "Wtorek",
        "Środa",
        "Czwartek",
        "Piątek",
        "Sobota",
        "Niedziela",
      ],
      changes: [],
      id: 0,
      options: [
        { value: "Wyłączność", text: "Wyłączność" },
        { value: "Wynajem", text: "Wynajem" },
      ],
    };
  },
  async created() {
    await this.fetchSettings();
    this.getCars();
    await this.handleGrounded();
    await this.handleRentals();
    this.assignServices();
  },

  beforeUpdate() {
    let clean = document.querySelectorAll(".sortable-chosen");
    if (clean.length) {
      clean.forEach((element) => {
        element.style.display = "none";
      });
    }
  },
  mounted() {
    // Register the beforeunload event listener when the component is mounted
    window.addEventListener("beforeunload", this.logOutEditor);

    // Set up your interval or any other setup code
    this.live = setInterval(() => {
      // Your logic here
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.logOutEditor);
    clearInterval(this.live);
  },

  methods: {
    async fetchSettings() {
      this.isLoading = true;
      await this.getSettings();
      await this.getCars();
      await this.getRentals();
      await this.$store
        .dispatch("fetchDetails", {
          week: this.$store.state.settings.week,
          year: this.$store.state.settings.adminYear,
        })
        .catch((error) => console.log(error));

      await this.$store.dispatch("getExcelReady", {
        adminWeek: this.$store.state.settings.adminWeek,
        year: this.$store.state.settings.adminYear,
      });
      await this.$store.dispatch("fetchGeneratedWeek", {
        week: this.$store.state.settings.adminWeek,
        year: this.$store.state.settings.adminYear,
      });
      await this.$store.dispatch(
        "getWeekServices",
        this.$store.state.settings.adminWeek
      );
      await this.$store.dispatch("getAllGrounded");
      this.getUsersList();
      this.logAsEditor("in");
      this.isLoading = false;
    },
    async sendSMS(item) {
      this.sended.push(item);
      await this.$store.dispatch("sendSMS", item);
    },
    async sendAllSMS() {
      this.sended = this.changes;
      this.sendedAll = true;
      await this.$store.dispatch("sendAllSMS", this.changes);
    },
    async getSettings() {
      this.isLoading = true;
      await this.$store.dispatch("fetchASettings");
    },
    async getCars() {
      await this.$store.dispatch("getCars");
    },
    async getRentals() {
      await this.$store.dispatch("getRentals");
    },
    assignServices() {
      const services = this.returnServices;
      // empty all cars
      this.returnDrivers.day.forEach((el) => {
        el.cars.forEach((singleCar) => {
          if (singleCar.service) {
            singleCar.service = null;
          }
        });
      });
      for (let i = 0; i < services.length; i++) {
        // empty
        let reg = services[i].reg;
        let desc = services[i].desc;
        let id = services[i].id;
        let serviceTime = services[i].serviceTime.split(":");
        let day = services[i].serviceDate.slice(-2);
        let lookForDay = this.returnDrivers.day.find((el) => el.day == day);
        let lookForCar = false;
        if (lookForDay) {
          lookForCar = lookForDay.cars.find((el) => el.reg === reg);
        }
        if (lookForCar) {
          lookForCar.service = null;
          if (!lookForCar.service) {
            lookForCar.service = [{ desc, serviceTime, id }];
          } else {
            if (!lookForCar.service.find((el) => el.id === id)) {
              // check if on serviceList
              lookForCar.service.push({ desc, serviceTime, id });
            }
          }
        }
      }
    },
    handleCarAssigment() {
      const daysInWeek = this.returnDrivers;
      const choosenCar = this.selectedDriverCar;
      const driver = this.returnDriverInfo;

      const weekNames = [
        "Poniedziałek",
        "Wtorek",
        "Środa",
        "Czwartek",
        "Piątek",
        "Sobota",
        "Niedziela",
      ];

      if (this.selectedOptions.includes("remove")) {
        daysInWeek.day.forEach((singleDay) => {
          const { day, month, shift, cars } = singleDay;
          cars.forEach((singleCar) => {
            if (singleCar.drivers.length) {
              if (
                singleCar.drivers[0].phone === driver.phone &&
                singleCar.reg !== choosenCar
              ) {
                singleCar.drivers = [];
              }
            }
          });
        });
        daysInWeek.night.forEach((singleDay) => {
          const { day, month, shift, cars } = singleDay;
          cars.forEach((singleCar) => {
            if (singleCar.drivers.length) {
              if (
                singleCar.drivers[0].phone === driver.phone &&
                singleCar.reg !== choosenCar
              ) {
                singleCar.drivers = [];
              }
            }
          });
        });
      }
      // day
      if (this.selectedOptions.includes("days")) {
        daysInWeek.day.forEach((singleDay, index) => {
          const { day, month, shift, cars } = singleDay;

          const findCar = cars.find((car) => car.reg === choosenCar);

          const emptyCars = cars.filter(
            (el) => !el.drivers.length && !el.grounded && !el.notOnStation
          );

          if (findCar && this.selectedDays.includes(index)) {
            const carId = findCar.id;
            const grounded = findCar.grounded;
            const notOnStation = findCar.notOnStation;

            if (findCar.drivers.length) {
              // check if this if driver not already if so do nothing
              const isAlready = findCar.drivers.find(
                (el) => el.phone === driver.phone
              );
              if (!isAlready) {
                // if other driver switch him to next free car - if no free car error
                if (emptyCars.length) {
                  const newCar = emptyCars[0];
                  newCar.drivers = findCar.drivers;
                  newCar.drivers.carId = newCar.id;
                  findCar.drivers = [];
                  findCar.drivers.push({
                    car: carId,
                    dayName: weekNames[index],
                    email: driver.email,
                    fullName: driver.fullName,
                    phone: driver.phone,
                    shift: "Dzień",
                    stateDay: `${day}.${month}.2024`,
                  });
                } else {
                  this.noFreeCarError = true;
                  throw new Error("Brak wolnego auta dla tej operacji.");
                }
              }
            } else {
              if (!grounded && !notOnStation) {
                findCar.drivers.push({
                  car: carId,
                  dayName: weekNames[index],
                  email: driver.email,
                  fullName: driver.fullName,
                  phone: driver.phone,
                  shift: "Dzień",
                  stateDay: `${day}.${month}.2024`,
                });
              }
            }
          }
        });
      }
      // night
      if (this.selectedOptions.includes("nights")) {
        daysInWeek.night.forEach((singleDay, index) => {
          const { day, month, shift, cars } = singleDay;

          const findCar = cars.find((car) => car.reg === choosenCar);

          const emptyCars = cars.filter(
            (el) => !el.drivers.length && !el.grounded && !el.notOnStation
          );

          if (findCar && this.selectedDays.includes(index)) {
            const carId = findCar.id;
            const grounded = findCar.grounded;
            const notOnStation = findCar.notOnStation;

            if (findCar.drivers.length) {
              // check if this if driver not already if so do nothing
              const isAlready = findCar.drivers.find(
                (el) => el.phone === driver.phone
              );
              if (!isAlready) {
                // if other driver switch him to next free car - if no free car error
                if (emptyCars.length) {
                  const newCar = emptyCars[0];
                  newCar.drivers = findCar.drivers;
                  newCar.drivers.carId = newCar.id;
                  findCar.drivers = [];
                  findCar.drivers.push({
                    car: carId,
                    dayName: weekNames[index],
                    email: driver.email,
                    fullName: driver.fullName,
                    phone: driver.phone,
                    shift: "Noc",
                    stateDay: `${day}.${month}.2024`,
                  });
                } else {
                  this.noFreeCarError = true;
                  throw new Error("Brak wolnego auta dla tej operacji.");
                }
              }
            } else {
              if (!grounded && !notOnStation) {
                findCar.drivers.push({
                  car: carId,
                  dayName: weekNames[index],
                  email: driver.email,
                  fullName: driver.fullName,
                  phone: driver.phone,
                  shift: "Noc",
                  stateDay: `${day}.${month}.2024`,
                });
              }
            }
          }
        });
      }
      // remove
    },
    getCar(val) {
      {
        const primaryCar = this.returnCars.find((el) => el.id === val);
        if (primaryCar) {
          return primaryCar.reg;
        } else {
          return "Brak";
        }
      }
    },
    async generateSchedule() {
      const week = this.$store.state.settings.adminWeek;
      const year =
        this.$store.state.settings.adminWeek === 1
          ? 2024
          : this.$store.state.settings.adminYear;
      const days = this.returnDrivers.day;
      const nights = this.returnDrivers.night;
      const history = this.changes;
      if (!this.update) {
        await this.$store
          .dispatch("generateWeek", { week, year, days, nights })
          .then((res) => {
            this.$bvToast.toast(`Grafik został wygenerowany pomyślnie.`, {
              title: "Utworzono nowy grafik",
              variant: "success",
              autoHideDelay: 5000,
            });
          });
        await this.$store.dispatch("fetchGeneratedWeek", {
          week: this.$store.state.settings.adminWeek,
          year:
            this.$store.state.settings.adminWeek === 1
              ? 2024
              : this.$store.state.settings.adminYear,
        });
      } else {
        this.$store
          .dispatch("updateWeek", { week, year, days, nights, history })
          .then((res) => {
            this.$bvToast.toast(`Grafik został zaktualizowany pomyślnie.`, {
              title: "Zaktualizowano",
              variant: "success",
              autoHideDelay: 5000,
            });
          });
      }
    },
    removeShift(val, index, carIndex, shift) {
      if (!this.startState) {
        this.$bvModal
          .msgBoxConfirm(
            "Po usunięciu zmiany zaktualizuj grafik, w innym wypadku po odświeżeniu strony zmiana będzie widoczna.",
            {
              title: `Usuwasz: ${val.fullName} - ${val.shift} / ${val.dayName}`,
              okTitle: "Potwierdzam",
              cancelTitle: "Powrót",
              centered: true,
              okVariant: "danger",
            }
          )
          .then((value) => {
            if (value && shift === "day") {
              const inx = this.returnDiff.day[index].cars[
                carIndex
              ].drivers.findIndex((el) => el.id === val.id);
              if (inx >= 0) {
                this.returnDiff.day[index].cars[carIndex].drivers.splice(
                  inx,
                  1
                );
              }
            }
            if (value && shift === "night") {
              const inx = this.returnDiff.night[index].cars[
                carIndex
              ].drivers.findIndex((el) => el.id === val.id);
              if (inx >= 0) {
                this.returnDiff.night[index].cars[carIndex].drivers.splice(
                  inx,
                  1
                );
              }
            }
          })
          .catch((err) => {
            console.log("Błąd");
          });
      }
    },
    handleEndDay(val) {
      this.stopGettingUserList();
      const newBox = val.to.parentElement;
      const oldBox = val.from.parentElement;
      const item = val.item._underlying_vm_;
      const oldShift = item.shift;
      const oldDay = item.stateDay;
      const oldWeekDay = item.dayName;
      let shift, day, weekDay;
      if (newBox.classList[1] === "day") shift = "Dzień";
      if (newBox.classList[1] === "night") shift = "Noc";

      if (val.to.classList[1] === "dayr") shift = "Dzień rezerwa";
      if (val.to.classList[1] === "nightr") shift = "Noc rezerwa";
      console.log(`Stara zmiana ${oldShift}`);
      console.log(`Nowa zmiana${shift}`);
      if (shift === "Dzień" || shift === "Noc") {
        day = newBox.parentElement.firstChild.children[0].firstChild.data;
        weekDay = newBox.parentElement.firstChild.children[0].lastChild.data;
        item.shift = shift;
      } else {
        let text = val.to.parentElement.parentElement.children[1].innerText
          .toString()
          .split("\n");
        day = text[1];
        weekDay = text[2];
        item.shift = shift;
      }

      if (day !== oldDay || shift !== oldShift) {
        const newReg = newBox.children[1].innerText;

        const findLast = this.changes.find((el) => el.id === item.id);
        if (findLast) {
          const findIndex = this.changes.findIndex((object) => {
            return object.id === findLast.id;
          });
          this.changes[findIndex].shift = shift;
          this.changes[findIndex].day = day;
          this.changes[findIndex].weekDay = weekDay;
          this.changes[findIndex].newReg = newReg;
        } else {
          this.changes.push({
            oldDay,
            oldWeekDay,
            day,
            weekDay,
            ...item,
            shift,
            oldShift,
            newReg,
          });
        }
      }
      this.getUsersList();
      this.$root.$emit("bv::hide::popover");
    },
    handleGrounded() {
      for (let i = 0; i < this.returnDrivers.day.length; i++) {
        let day = this.returnDrivers.day[i].day;
        let formattedDay = day <= 9 ? `0${day}` : `${day}`;
        let month = this.returnDrivers.day[i].month;
        // to remove
        let year = 2024;
        //
        let formattedMonth = String(month).padStart(2, "0");

        let driversToMove = [];
        // cleaning
        for (let b = 0; b < this.returnDrivers.day[i].cars.length; b++) {
          this.returnDrivers.day[i].cars[b].grounded = false;
          this.returnDrivers.day[i].cars[b].notOnStation = false;
          let groundedList = this.$store.state.grounded.all.filter(
            (el) =>
              el.attributes.carReg === this.returnDrivers.day[i].cars[b].reg
          );
          if (groundedList.length) {
            for (let c = 0; c < groundedList.length; c++) {
              if (
                this.$dayjs(`${year}-${formattedMonth}-${day}`).isBetween(
                  groundedList[c].attributes.dayStart,
                  groundedList[c].attributes.dayEnd
                ) ||
                (groundedList[c].attributes.shiftStart === "Dzień" &&
                  groundedList[c].attributes.dayStart ===
                    `${year}-${formattedMonth}-${formattedDay}`) ||
                (groundedList[c].attributes.shiftEnd === "Dzień" &&
                  groundedList[c].attributes.dayEnd ===
                    `${year}-${formattedMonth}-${formattedDay}`) ||
                (groundedList[c].attributes.shiftEnd === "Noc" &&
                  groundedList[c].attributes.dayEnd ===
                    `${year}-${formattedMonth}-${formattedDay}` &&
                  groundedList[c].attributes.dayStart !==
                    groundedList[c].attributes.dayEnd)
              ) {
                this.returnDrivers.day[i].cars[b].grounded = true;
                if (groundedList[c].attributes.notOnStation) {
                  this.returnDrivers.day[i].cars[b].notOnStation = true;
                }
                if (this.returnDrivers.day[i].cars[b].drivers.length) {
                  driversToMove.push(
                    this.returnDrivers.day[i].cars[b].drivers[0]
                  );
                  this.returnDrivers.day[i].cars[b].drivers = [];
                }
              }
            }
          }
        }
        if (driversToMove.length) {
          for (let d = 0; d < driversToMove.length; d++) {
            let findCar = this.returnDrivers.day[i].cars.find(
              (el) => !el.drivers.length && !el.grounded
            );
            if (findCar) {
              findCar.drivers.push(driversToMove[d]);
            } else {
              this.returnDrivers.day[i].reserve.push(driversToMove[d]);
            }
          }
        }
      }
      //night
      for (let i = 0; i < this.returnDrivers.night.length; i++) {
        let day = this.returnDrivers.night[i].day;
        let formattedDay = day <= 9 ? `0${day}` : `${day}`;
        let month = this.returnDrivers.night[i].month;
        let formattedMonth = String(month).padStart(2, "0");
        let year = 2024;

        let driversToMove = [];
        // cleaning
        for (let b = 0; b < this.returnDrivers.night[i].cars.length; b++) {
          this.returnDrivers.night[i].cars[b].grounded = false;
          this.returnDrivers.night[i].cars[b].notOnStation = false;
          let groundedList = this.$store.state.grounded.all.filter(
            (el) =>
              el.attributes.carReg === this.returnDrivers.night[i].cars[b].reg
          );
          if (groundedList.length) {
            for (let c = 0; c < groundedList.length; c++) {
              if (
                this.$dayjs(`${year}-${formattedMonth}-${day}`).isBetween(
                  groundedList[c].attributes.dayStart,
                  groundedList[c].attributes.dayEnd
                ) ||
                (groundedList[c].attributes.shiftStart === "Noc" &&
                  groundedList[c].attributes.dayStart ===
                    `${year}-${formattedMonth}-${formattedDay}`) ||
                (groundedList[c].attributes.shiftEnd === "Noc" &&
                  groundedList[c].attributes.dayEnd ===
                    `${year}-${formattedMonth}-${formattedDay}`) ||
                (groundedList[c].attributes.shiftStart === "Dzień" &&
                  groundedList[c].attributes.dayStart ===
                    `${year}-${formattedMonth}-${formattedDay}` &&
                  groundedList[c].attributes.dayStart !==
                    groundedList[c].attributes.dayEnd)
              ) {
                this.returnDrivers.night[i].cars[b].grounded = true;
                if (groundedList[c].attributes.notOnStation) {
                  this.returnDrivers.night[i].cars[b].notOnStation = true;
                }
                // handle driver move
                if (this.returnDrivers.night[i].cars[b].drivers.length) {
                  driversToMove.push(
                    this.returnDrivers.night[i].cars[b].drivers[0]
                  );
                  this.returnDrivers.night[i].cars[b].drivers = [];
                }
              }
            }
          }
        }
        if (driversToMove.length) {
          for (let d = 0; d < driversToMove.length; d++) {
            let findCar = this.returnDrivers.night[i].cars.find(
              (el) => !el.drivers.length && !el.grounded
            );
            if (findCar) {
              findCar.drivers.push(driversToMove[d]);
            } else {
              this.returnDrivers.night[i].reserve.push(driversToMove[d]);
            }
          }
        }
      }
    },
    handleRentals() {
      const rentalsList = this.returnRentals;
      for (let i = 0; i < this.returnDrivers.day.length; i++) {
        let day = this.returnDrivers.day[i].day;
        let formattedDay = day <= 9 ? `0${day}` : `${day}`;
        let month = this.returnDrivers.day[i].month;
        // to remove
        let year = 2024;
        //
        let formattedMonth = String(month).padStart(2, "0");

        let driversToMove = [];
        // cleaning
        for (let b = 0; b < this.returnDrivers.day[i].cars.length; b++) {
          this.returnDrivers.day[i].cars[b].isRented = false;
          this.returnDrivers.day[i].cars[b].notOnStation = false;
          let groundedList = rentalsList.filter(
            (el) => el.rented_car === this.returnDrivers.day[i].cars[b].reg
          );
          if (groundedList.length) {
            for (let c = 0; c < groundedList.length; c++) {
              const startTime =
                parseInt(groundedList[c].start_time.split(":")[0]) > 17
                  ? "Noc"
                  : "Dzień";
              let endTime = "Dzień";
              if (groundedList[c].end_time) {
                endTime =
                  parseInt(groundedList[c].end_time.split(":")[0]) < 18
                    ? "Dzień"
                    : "Noc";
              }
              let endDateCorrect = null;
              if (groundedList[c].end_date) {
                endDateCorrect = groundedList[c].end_date;
              } else {
                endDateCorrect = this.$dayjs()
                  .add("14", "days")
                  .format("YYYY-MM-DD");
              }
              if (
                this.$dayjs(`${year}-${formattedMonth}-${day}`).isBetween(
                  groundedList[c].start_date,
                  endDateCorrect
                ) ||
                (startTime === "Dzień" &&
                  groundedList[c].start_date ===
                    `${year}-${formattedMonth}-${formattedDay}`) ||
                (endTime === "Dzień" &&
                  endDateCorrect ===
                    `${year}-${formattedMonth}-${formattedDay}`) ||
                (endTime === "Noc" &&
                  endDateCorrect ===
                    `${year}-${formattedMonth}-${formattedDay}` &&
                  groundedList[c].start_date !== endDateCorrect)
              ) {
                this.returnDrivers.day[i].cars[b].isRented = true;
                if (groundedList[c].notOnStation) {
                  this.returnDrivers.day[i].cars[b].notOnStation = true;
                }
                if (this.returnDrivers.day[i].cars[b].drivers.length) {
                  const isAlreadyThere =
                    this.returnDrivers.day[i].cars[b].drivers[0]?.phone ===
                    groundedList[c].phoneNumber
                      ? true
                      : false;
                  if (!isAlreadyThere) {
                    driversToMove.push(
                      this.returnDrivers.day[i].cars[b].drivers[0]
                    );
                  }
                  this.returnDrivers.day[i].cars[b].drivers = [
                    {
                      fullName: `${groundedList[c].first_name} ${groundedList[c].last_name}`,
                      type: groundedList[c].type,
                      phoneNumber: groundedList[c].phone_number,
                    },
                  ];
                } else {
                  this.returnDrivers.day[i].cars[b].drivers = [
                    {
                      fullName: `${groundedList[c].first_name} ${groundedList[c].last_name}`,
                      type: groundedList[c].type,
                      phoneNumber: groundedList[c].phone_number,
                    },
                  ];
                }
              }
            }
          }
        }
        if (driversToMove.length) {
          for (let d = 0; d < driversToMove.length; d++) {
            let findCar = this.returnDrivers.day[i].cars.find(
              (el) => !el.drivers.length && !el.grounded && !el.isRented
            );
            if (findCar) {
              findCar.drivers.push(driversToMove[d]);
            } else {
              this.returnDrivers.day[i].reserve.push(driversToMove[d]);
            }
          }
        }
      }
      //night
      for (let i = 0; i < this.returnDrivers.night.length; i++) {
        let day = this.returnDrivers.night[i].day;
        let formattedDay = day <= 9 ? `0${day}` : `${day}`;
        let month = this.returnDrivers.night[i].month;
        let formattedMonth = String(month).padStart(2, "0");
        let year = 2024;

        let driversToMove = [];
        // cleaning
        for (let b = 0; b < this.returnDrivers.night[i].cars.length; b++) {
          this.returnDrivers.night[i].cars[b].isRented = false;
          this.returnDrivers.night[i].cars[b].notOnStation = false;
          let groundedList = rentalsList.filter(
            (el) => el.rented_car === this.returnDrivers.night[i].cars[b].reg
          );
          if (groundedList.length) {
            for (let c = 0; c < groundedList.length; c++) {
              const startTime =
                parseInt(groundedList[c].start_time.split(":")[0]) > 17
                  ? "Noc"
                  : "Dzień";
              let endTime = "Dzień";
              if (groundedList[c].end_time) {
                endTime =
                  parseInt(groundedList[c].end_time.split(":")[0]) < 18
                    ? "Dzień"
                    : "Noc";
              }
              let endDateCorrect = null;
              if (groundedList[c].end_date) {
                endDateCorrect = groundedList[c].end_date;
              } else {
                endDateCorrect = this.$dayjs()
                  .add("14", "days")
                  .format("YYYY-MM-DD");
              }
              if (
                this.$dayjs(`${year}-${formattedMonth}-${day}`).isBetween(
                  groundedList[c].start_date,
                  endDateCorrect
                ) ||
                (startTime === "Noc" &&
                  groundedList[c].start_date ===
                    `${year}-${formattedMonth}-${formattedDay}`) ||
                (endTime === "Noc" &&
                  endDateCorrect ===
                    `${year}-${formattedMonth}-${formattedDay}`) ||
                (startTime === "Dzień" &&
                  groundedList[c].start_date ===
                    `${year}-${formattedMonth}-${formattedDay}` &&
                  groundedList[c].start_date !== endDateCorrect)
              ) {
                this.returnDrivers.night[i].cars[b].isRented = true;
                if (groundedList[c].notOnStation) {
                  this.returnDrivers.night[i].cars[b].notOnStation = true;
                }
                // handle driver move
                // if is there online continue
                if (this.returnDrivers.night[i].cars[b].drivers.length) {
                  const isAlreadyThere =
                    this.returnDrivers.night[i].cars[b].drivers[0]?.phone ===
                    groundedList[c].phoneNumber
                      ? true
                      : false;
                  if (!isAlreadyThere) {
                    driversToMove.push(
                      this.returnDrivers.night[i].cars[b].drivers[0]
                    );
                  }
                  this.returnDrivers.night[i].cars[b].drivers = [
                    {
                      fullName: `${groundedList[c].first_name} ${groundedList[c].last_name}`,
                      type: groundedList[c].type,
                      phoneNumber: groundedList[c].phone_number,
                    },
                  ];
                } else {
                  this.returnDrivers.night[i].cars[b].drivers = [
                    {
                      fullName: `${groundedList[c].first_name} ${groundedList[c].last_name}`,
                      type: groundedList[c].type,
                      phoneNumber: groundedList[c].phone_number,
                    },
                  ];
                }
              }
            }
          }
        }
        if (driversToMove.length) {
          for (let d = 0; d < driversToMove.length; d++) {
            let findCar = this.returnDrivers.night[i].cars.find(
              (el) => !el.drivers.length && !el.grounded && !el.isRented
            );
            if (findCar) {
              findCar.drivers.push(driversToMove[d]);
            } else {
              this.returnDrivers.night[i].reserve.push(driversToMove[d]);
            }
          }
        }
      }
    },
    altReserve(shift) {
      this.selectedRes = shift;
      this.$bvModal.show("alt-reserve");
    },
    showBlock() {
      this.$bvModal.show("car-block");
    },

    changeReserve(obj) {
      const shiftId = obj.person.id;
      const nights = this.returnDrivers.night;
      const days = this.returnDrivers.day;
      const dayIndex = obj.select.weekIndex;
      const carReg = obj.select.carReg;
      if (obj.select.shift === "day") {
        //loop day
        for (let i = 0; i < days.length; i++) {
          let index = days[i].reserve.findIndex((item) => item.id === shiftId);
          let nightIndex = nights[i].reserve.findIndex(
            (item) => item.id === shiftId
          );
          if (index >= 0) {
            days[i].reserve.splice(index, 1);
            let car = days[dayIndex].cars.find((car) => car.reg === carReg);
            if (car) {
              obj.person.shift = "Dzień";
              car.drivers.push(obj.person);
              this.changes.push({
                oldDay: obj.person.stateDay,
                oldWeekDay: obj.person.dayName,
                day: this.createDays[obj.select.weekIndex],
                weekDay: this.weekNames[obj.select.weekIndex],
                ...obj.person,
                shift: obj.person.shift,
                oldShift: "Dzień rezerwa",
                newReg: car.reg,
              });
              break;
            }
          }
          if (nightIndex >= 0) {
            nights[i].reserve.splice(nightIndex, 1);
            let car = days[dayIndex].cars.find((car) => car.reg === carReg);
            if (car) {
              obj.person.shift = "Dzień";
              car.drivers.push(obj.person);
              this.changes.push({
                oldDay: obj.person.stateDay,
                oldWeekDay: obj.person.dayName,
                day: this.createDays[obj.select.weekIndex],
                weekDay: this.weekNames[obj.select.weekIndex],
                ...obj.person,
                shift: obj.person.shift,
                oldShift: "Noc rezerwa",
                newReg: car.reg,
              });
              break;
            }
          }
        }
      }
      if (obj.select.shift === "night") {
        //loop night
        for (let i = 0; i < nights.length; i++) {
          let dayIdx = days[i].reserve.findIndex((item) => item.id === shiftId);
          let index = nights[i].reserve.findIndex(
            (item) => item.id === shiftId
          );
          if (index >= 0) {
            nights[i].reserve.splice(index, 1);
            let car = nights[dayIndex].cars.find((car) => car.reg === carReg);
            if (car) {
              obj.person.shift = "Noc";
              car.drivers.push(obj.person);
              this.changes.push({
                oldDay: obj.person.stateDay,
                oldWeekDay: obj.person.dayName,
                day: this.createDays[obj.select.weekIndex],
                weekDay: this.weekNames[obj.select.weekIndex],
                ...obj.person,
                shift: obj.person.shift,
                oldShift: "Noc rezerwa",
                newReg: car.reg,
              });
              break;
            }
          }
          if (dayIdx >= 0) {
            days[i].reserve.splice(dayIdx, 1);
            let car = nights[dayIndex].cars.find((car) => car.reg === carReg);
            if (car) {
              obj.person.shift = "Noc";
              car.drivers.push(obj.person);
              this.changes.push({
                oldDay: obj.person.stateDay,
                oldWeekDay: obj.person.dayName,
                day: this.createDays[obj.select.weekIndex],
                weekDay: this.weekNames[obj.select.weekIndex],
                ...obj.person,
                shift: obj.person.shift,
                oldShift: "Dzień rezerwa",
                newReg: car.reg,
              });
              break;
            }
          }
        }
      }
    },
    reset() {
      this.startState = !this.startState;
    },
    async showAssigned() {
      if (!this.isList) {
        await this.$store.dispatch("assignedDrivers");
        this.isList = true;
        this.$bvToast.show("assigned");
      }
      this.isList = false;
      this.$bvToast.hide("assigned");
    },
    getUsersList() {
      this.live = setInterval(() => {
        this.$store.dispatch("getUsersInEdit");
      }, 2500);
    },
    logAsEditor(mode) {
      this.$store.dispatch("logAsEditor", mode);
      if (mode === "out") {
        console.log("out");
      }
    },
    logOutEditor() {
      this.$store.dispatch("logAsEditor", "out");
    },
    stopGettingUserList() {
      if (this.live) {
        clearInterval(this.live);
        this.live = null;
      }
    },

    hasTwoShiftsOnSameDay(driver, index, mode) {
      const days = this.returnDrivers.day;
      const night = this.returnDrivers.night;
      let hasTwoShifts = "";
      if (mode === "day") {
        for (let i = 0; i < night[index].cars.length; i++) {
          const singleCar = night[index].cars[i];
          if (
            singleCar.drivers.length &&
            singleCar.drivers[0].phone === driver.phone &&
            !singleCar.isRented
          ) {
            hasTwoShifts = "two-shifts";
          }
        }
      }
      if (mode === "night") {
        for (let i = 0; i < days[index].cars.length; i++) {
          const singleCar = days[index].cars[i];
          if (
            singleCar.drivers.length &&
            singleCar.drivers[0].phone === driver.phone &&
            !singleCar.isRented
          ) {
            hasTwoShifts = "two-shifts";
          }
        }
      }

      return hasTwoShifts;
    },
  },
  computed: {
    createDays() {
      const adminWeek = this.$store.state.settings.adminWeek;
      const currentWeek = this.$dayjs().weekday(0).week();
      const currentYear = this.$store.state.settings.adminYear;
      const week = this.$dayjs()
        .week(this.$store.state.settings.adminWeek)
        .weekday(0);
      const weekDays = [];
      for (let i = 0; i < 7; i++) {
        let day = this.$dayjs(week).year(currentYear).weekday(0).add(i, "days");
        weekDays.push(`${day.$D}.${day.$M + 1}.${day.year()}`);
      }
      return weekDays;
    },
    update() {
      const whichWeek = this.$store.state.settings.adminWeek;
      const findWeek = this.$store.state.generatedWeeks.find(
        (el) =>
          el.week === this.$store.state.settings.adminWeek && el.year === 2024
      );
      return !this.isLoading && findWeek ? true : false;
    },
    returnDrivers() {
      const setup = this.$store.getters["getCalendar"];
      if (!this.isLoading && this.update && !this.startState) {
        return !this.isLoading && this.returnDiff;
      } else {
        const days = setup.filter((el) => el.shift === "dzień");
        const nights = setup.filter((el) => el.shift === "noc");
        return !this.isLoading && { day: days, night: nights };
      }
    },
    returnDriversNames() {
      const driversList = [];

      if (this.returnDrivers) {
        this.returnDrivers.day.forEach((el) => {
          const { cars, day, month } = el;
          cars.forEach((singleCar) => {
            singleCar.drivers.forEach((driver) => {
              const findDriver = driversList.find(
                (el) => el.fullName === driver.fullName
              );
              if (!findDriver) {
                driversList.push({
                  fullName: driver.fullName,
                  phone: driver.phone,
                  email: driver.email,
                  possibleCars: [{ reg: singleCar.reg, num: 1 }],
                });
              } else {
                const findCar = findDriver.possibleCars.find(
                  (el) => el.reg === singleCar.reg
                );
                if (!findCar) {
                  findDriver.possibleCars.push({ reg: singleCar.reg, num: 1 });
                } else {
                  findCar.num++;
                }
                findDriver.possibleCars.sort((a, b) => b.num - a.num);
              }
            });
          });
        });
        this.returnDrivers.night.forEach((el) => {
          const { cars, day, month } = el;
          cars.forEach((singleCar) => {
            singleCar.drivers.forEach((driver) => {
              const findDriver = driversList.find(
                (el) => el.fullName === driver.fullName
              );
              if (!findDriver) {
                driversList.push({
                  fullName: driver.fullName,
                  phone: driver.phone,
                  possibleCars: [{ reg: singleCar.reg, num: 1 }],
                });
              } else {
                const findCar = findDriver.possibleCars.find(
                  (el) => el.reg === singleCar.reg
                );
                if (!findCar) {
                  findDriver.possibleCars.push({ reg: singleCar.reg, num: 1 });
                } else {
                  findCar.num++;
                }
                findDriver.possibleCars.sort((a, b) => b.num - a.num);
              }
            });
          });
        });
        return !this.isLoading && this.returnDrivers && driversList;
      } else {
        return [];
      }
    },
    returnDriverInfo() {
      if (this.returnDriversNames.length) {
        return this.returnDriversNames.find(
          (el) => el.fullName === this.selectedDriver
        );
      } else {
        return null;
      }
    },
    returnCars() {
      return !this.isLoading && this.$store.getters["returnCars"];
    },
    returnDiff() {
      const week = this.$store.state.generatedWeeks[0];
      return { day: week.days, night: week.nights };
    },
    returnServices() {
      return !this.isLoading && this.$store.getters["weekServices"];
    },
    returnCurrentUsers() {
      return !this.isLoading && this.$store.getters["getUsersList"];
    },
    returnRentals() {
      return !this.isLoading && this.$store.getters["getRentals"];
    },
    returnChanges() {
      const week = this.$store.state.generatedWeeks[0].history;
      if (week.length) {
        return week;
      } else {
        return [];
      }
    },
    returnCapacity() {
      const nightArray = [];
      const dayArray = [];
      const nights = this.returnDrivers.night;
      const days = this.returnDrivers.day;
      for (let i = 0; i < nights.length; i++) {
        let cars = nights[i].cars;
        let drivers = 0;
        let availableCars = 0;
        let leftCars = [];
        for (let a = 0; a < cars.length; a++) {
          drivers += cars[a].drivers.length;
          if (!cars[a].grounded) {
            availableCars += 1;
          }
          if (cars[a].drivers.length === 0 && !cars[a].grounded) {
            leftCars.push(cars[a].reg);
          }
        }
        nightArray.push({ drivers, availableCars, leftCars });
      }
      for (let i = 0; i < days.length; i++) {
        let cars = days[i].cars;
        let drivers = 0;
        let availableCars = 0;
        let leftCars = [];

        for (let a = 0; a < cars.length; a++) {
          drivers += cars[a].drivers.length;
          if (!cars[a].grounded) {
            availableCars += 1;
          }
          if (cars[a].drivers.length === 0 && !cars[a].grounded) {
            leftCars.push(cars[a].reg);
          }
        }
        dayArray.push({ drivers, availableCars, leftCars });
      }
      return !this.isLoading && { day: dayArray, night: nightArray };
    },
    returnLastShifts() {},
    getAssignedList() {
      return (
        !this.isLoading &&
        this.getCars &&
        this.$store.getters["returnAssignedDrivers"]
      );
    },
  },
  watch: {
    startState(val) {
      if (val === true) {
        this.handleGrounded();
      }
    },
  },
};
</script>

<style scoped>
.week-calendar {
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.assign {
  color: black;
  padding: 5px;
  border: 1px solid black;
  margin-top: 5px;
}
.box-row {
  gap: 20px;
  justify-content: center;
  margin: 40px 0;
}
.box-title .right {
  font-size: 22px;
}
.box-title {
  background: #364bb4;
  color: #fff;
  padding: 5px;
}
.reserve {
  background-color: rgb(231, 65, 65);
  text-align: center;
  color: #fff;
  padding: 10px;
}

.grounded {
  background-color: rgb(39, 39, 39);
  color: #fff;
}
.missing {
  background-color: rgba(155, 98, 98, 0.87);
}

.reserve-item {
  padding: 20px;
  text-align: center;
  margin: 10px 0;
  border: 1px solid black;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.shift-box {
  height: auto;
  width: 13%;
  border: 1px solid rgb(41, 41, 41);
  border-radius: 5px;
}
.drop-zone {
  height: 100%;
}
.drop-reserve {
  min-height: 100px;
}
.dropbox {
  min-height: 50px;
}

.drop-zone .danger {
  background: rgb(241, 121, 121);
}
.box-item {
  border: 1px solid rgb(179, 179, 179);
  margin: 5px 10px;
  padding: 5px;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.box-item .regplate {
  text-align: center;
}
.box-item .single {
  background-color: #9c9c9c;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.last {
  background: rgb(130, 177, 130) !important;
}
.oldshift {
  background: rgb(248, 125, 125);
  color: rgb(255, 255, 255);
  border-radius: 15px;
  padding: 15px;
}
.newshift {
  background: rgb(192, 255, 156);
  border-radius: 15px;
  padding: 15px;
}
.changes-row {
  gap: 30px 0px;
}
.changes-box {
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
}
.users-list {
  display: flex;
}
.single-user {
  margin-top: 4px;
  margin-right: 8px;
  font-size: 14px;
}
.dlist {
  padding: 5px 0;
}
.service-box {
  border: 1px solid #fff;
  margin: 5px 3px;
}
@media (max-width: 700px) {
  .box-row {
    flex-direction: column;
  }
  .shift-box {
    height: auto;
    width: 100%;
    border: 1px solid rgb(41, 41, 41);
    border-radius: 5px;
  }
  .title {
    margin-left: 10px;
  }
  .head {
    flex-direction: column;
    width: 95%;
  }
  .head button {
    width: 100%;
    margin-left: 0px !important;
  }
  .action {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    width: 95%;
  }
  .action button {
    width: 100%;
  }
}

.car {
  text-align: center;
  border: 1px solid #4e73df;
  border-radius: 8px;
  cursor: pointer;
  padding: 32px 0;
}
.car.active {
  background-color: #4e73df;
  color: #fff;
}

.single.rental {
  background-color: #ff9100;
  position: relative;
}
.single.rented {
  background-color: #28b105;
  position: relative;
}
.single.rental .info,
.single.rented .info {
  position: absolute;
  top: 0px;
  right: 4px;
  font-size: 10px;
}

.single.two-shifts {
  background-color: #4e73df;
}
</style>
